@import "../../style.scss";

.home {
  @include themify($themes) {
    padding: 20px 0px;
    background-color: themed("bgSoft");
    display: flex;
    flex-direction: row;

    @include mobile {
      padding: 5px;
    }

    @include tablet {
      padding: 10px;
    }

    .leftHome {
      padding: 0px 50px;
      display: flex;
      flex-direction: column;
      flex: 9;
      overflow: hidden;

      @include mobile {
        padding: 0px;
      }
  
      @include tablet {
        padding: 0px;
      }
    }

    .section {
      display: flex;
      flex-direction: column;
      &.social, &.news {
        flex-direction: column; 
      }
    }

    .categories {
      padding-bottom: 20px;
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
    }

    .title {
      margin: 35px 0px 20px 0px;
      color: themed("textColor");
      font-size: 25px;

      @include mobile {
        font-size: 18px;  // smaller font size for mobile
      }
    }

    .widget {
      border-radius: 10px;
      padding: 5px 10px;
      background-color: #6D1D1D;
      color: white;
      border: 1px lightgray solid;
      align-items: center;
      display: flex;
      gap: 10px;

      &.inactive {
        background-color: themed("bg");
        color: themed("textColor");
      }
    }
  }
}